import React from 'react'
import styled from 'styled-components';
import { Accordion, Card, Button, Table, Row, Col } from 'react-bootstrap';
import {Link} from 'gatsby';
import { socialMediaIcons, navigationLinks } from '../../constants/FooterLinks';
export default function Footer() {
    return (
      <Footerbar>
        <Social>
          {socialMediaIcons.map((icon) => (
            <a key={icon.id} href={icon.url} target="_blank" rel="noopener noreferrer">
              <img src={icon.src} alt={icon.alt} />
            </a>
          ))}
        </Social>
        <Navigation>
          <ul>
            {navigationLinks.map((link, index) => (
              <li key={index}>
                <Link to={link.url} className="footerText">
                  {link.text} {index < navigationLinks.length - 1 && <span>|</span>}
                </Link>
              </li>
            ))}
          </ul>
        </Navigation>
    </Footerbar>
    )
}

const FooterComp = styled.div`
height:119px;
width:100%;
display:flex;
align-items:center;
justify-content:center;
flex-direction:column;
background-position:center;
background-repeat:no-repeat;
background-size:cover;

p{
  font-size:52px;
  line-height:50px;
  color:#fff;
  font-weight:500;
  text-align:center;
  @media(max-width:600px){
    font-size:36px;
  }
}

.title{
  font-family:Rajdhani;
  margin-top:80px;
}
@media(max-width:600px){
  .title{
    font-family:Rajdhani;
    margin-top:30px;
  }
}
`
const Footerbar = styled.div`
min-height:119px;
width:100%;
display:flex;
align-items:center;
justify-content:space-between;
padding:10px 30px;

background: #212E33;
@media(max-width:768px){
    padding:20px 30px;
  }
@media(max-width:700px){
    flex-direction:column;
  }
`
const Social = styled.div `
display:flex;
img{
  width:35px;
  height:auto;
  margin-right:10px;
}
`
const Navigation = styled.div`
ul{
  display:flex;
  list-style:none;
  margin: 0 !important;
  @media(max-width:600px){
    margin:10px 0 !important;
    flex-direction:column;
    padding:0;
  }
}
ul li {
    list-style:none;
  margin:0 5px;
  color: #3FD4E2;
  font-size:14px;
  text-align:center;
  span{
    color:#fff;
  }
  @media(max-width:600px){
    margin:5px 0;
    text-align:center;
    span{display:none}
  }
  }
  .footerText{
    color:#E1892D;
    font-family:Rajdhani;
    font-size:16px;
    line-height:21px;
    font-weight:500;
    text-decoration:none;
  }
  .footerText:hover{
    color:#ffffff;
  }
`