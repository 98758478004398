import React from 'react'
import * as queryString from "query-string";
import Redirect from '../Components/Redirect/Redirect'

export default function redirect(props) {
    const url_params = queryString.parse(props.location.search);

    return (
        <div>
            <Redirect url_params={url_params}/>
        </div>
    )
}
