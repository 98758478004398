import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Footer from "../Footer/Footer";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import config from "../../aws-exports";
import Loader from "./../../assets/loading.gif";
import "../../main.css";
import { checkClientTempTokenFromRedis, getEsDownDocs, createOnRedis } from "../../graphql/queries"
import {navigate} from 'gatsby';
import GeneralPopup from '../Modals/GeneralPopup';
import  secureLocalStorage  from  "react-secure-storage";
Amplify.configure(config);

export default function Redirect(props) {
  const {consoleLog} = require('../commonFunctions.js');
  const [loading, setLoading] = useState(true);
  const [redirectContent, setRedirectContent] = useState({});
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState({});

  async function checkLogin(){
    let client_id = props.url_params.id;
    let doc_type_id = props.url_params.doc_type_id;
    
    var client_data = secureLocalStorage.getItem('client_data');
    if(client_data){
      client_data = JSON.parse(client_data);
    }

    // if(!client_id || !doc_type_id){
    //   if(client_data){
    //     redirectToDocumentPage();
    //   }
    //   else{
    //     redirectToLoginPage();
    //   }
    // }


    const result = await API.graphql({
      query: checkClientTempTokenFromRedis,
      variables: {
        client_id: client_id,
        logged_in_id: client_data?.client_id
      }
    });

    let queryRes = JSON.parse(result.data.checkClientTempTokenFromRedis);
    let body = JSON.parse(queryRes).body;    

    consoleLog("body", body);
    consoleLog("client_data", client_data);
    
    setLoading(false);
    
    if(body.success){
      let timestamp = new Date()/1000;
      let db_timestamp = new Date(body.data.temporary_token_gen_time)/1000 ?? 0;

      if(client_data && client_data.client_id == body.data.client_id){
        // Go to New Doc Page // if same user is logged in
        console.log("Go to New Doc Page");
        redirectToNewDocPage();
      }
      else if((timestamp - db_timestamp) < 120){
        if(client_data){
          // Option to Switch Login
          consoleLog("Option to Switch Login", ">");
          switchLogin(body.data);
        }
        else{
          // Login from new account
          consoleLog("Login from new account", ">");
          saveLoginAndRedirect(body.data);
        }
      }
    }
    else{
      if(client_data){
        // Login from Current Account
        consoleLog("Login from Current Account", ">");
        setRedirectContent({
          show: true,
          text: "You don't have permission to perform this operation",
          btnText: "Go to My Account",
          onClick: redirectToDocumentPage
        })
      }
      else{ 
        // Login Failed
        setRedirectContent({
          show: true,
          text: "We could not authenticate your request",
          btnText: "Login to Prodocs",
          onClick: redirectToLoginPage
        })
      }
    }
  }

  function switchLogin(data){
    setModalData({
      titleText: "Switch Login",
      bodyText: `You are already logged in with a different account. Do you want to switch login to ${data.email}?`,
      confirm: ()=>saveLoginAndRedirect(data),
      confirmNo: redirectToDocumentPage
    });
    setIsOpen(true);
  }

  async function saveLoginAndRedirect(data){
    await saveNewLogin(data);
    redirectToNewDocPage();
  }

  async function saveNewLogin(data){
    delete data.temporary_token;
    delete data.temporary_token_gen_time;

    const esd = await API.graphql({
      query: getEsDownDocs,
        variables: {
            team_id: data.team_id
        }
    });
    console.log('saveNewLogin es_down_docs>',esd.data.getEsDownDocs);
    console.log('saveNewLogin data>',data);
    secureLocalStorage.setItem('es_down_docs', esd.data.getEsDownDocs);
    secureLocalStorage.setItem('client_data', JSON.stringify(data));	
    secureLocalStorage.setItem('user_token', data.token);

    const create_on_redis = await API.graphql({
        query: createOnRedis,
        variables: {
          id: data.client_id,
          index: 'prodocs_user_auth_data',
          data: JSON.stringify(data)
        }
    });
    consoleLog('saveNewLogin create_on_redis:', create_on_redis);
  }

  function redirectToDocumentPage(){
    navigate('/document');
  }

  function redirectToLoginPage(){
    navigate('/login');
  }

  function redirectToNewDocPage(){
    // let order_id = props.url_params.order_id;
    // let doc_type_id = props.url_params.doc_type_id;
    // if(props.url_params.page){
    console.log('redirectToNewDocPage');
    //   navigate('/' + props.url_params.page);
    // } else {
    //   navigate(`/createdoc?order_id=${order_id}&doc_type_id=${doc_type_id}`);
    // }
    if(props.url_params.page){
      let nav = '/' + props.url_params.page;
      if(props.url_params.doc_type_id && props.url_params.order_id){
        nav += '?doc_type_id='+props.url_params.doc_type_id+'&order_id='+props.url_params.order_id;
      }
      if(props.url_params.doc_type_id && !props.url_params.order_id){
        nav += '?doc_type_id='+props.url_params.doc_type_id;
      }
      navigate(nav);
    }
  }

  useEffect(()=>{
    checkLogin();
  }, []);

  return (
    <>
      { modalIsOpen && <GeneralPopup 
        titleText={modalData.titleText}
        bodyText={modalData.bodyText}
        fade={false}
        showModal={modalIsOpen}
        setShowModal={setIsOpen}
        confirm={modalData.confirm}
        confirmNo={modalData.confirmNo}
      /> }
      <Wrapper>
        {loading ? (
          <LoadingContainer>
            <img className="loader" src={Loader} />
          </LoadingContainer>
        ) : (
          <>
          {
            redirectContent.show == true ?
            (
              <div className="redirect-page-text-wrap">
                <div className="redirect-page-text"> { redirectContent.text } </div>
                <button className="redirect-page-btn" onClick={redirectContent.onClick}>
                  {redirectContent.btnText}
                </button>
              </div>
            ) : ""
          }
          </>
        )}
      </Wrapper>
      <Footer />
    </>
  );
}

const Wrapper = styled.div`
  min-height: 95vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f9fa;
  position: relative;

  & .redirect-page-text-wrap{
    text-align: center;
    font-size: 24px;
  }
  & .redirect-page-btn{
    margin-top: 20px;
    padding: 10px 20px;
    background: #f7941c;
    border-radius: 3px;
    font-size: 16px;
    font-family: Rajdhani;
    color: #212e33;
    outline: none;
    border: none;
    font-weight: 600;
    text-transform: uppercase;
  }
`

const LoadingContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  .loader {
    -webkit-user-select: none;
    margin: auto;
    // background-color: rgba(255,255,255, 0.5);
    // transition: background-color 300ms;
  }
`