import React, { useState } from 'react'
import { Button, Modal, } from 'react-bootstrap';
import Cancel from '../../assets/cross.svg';
import "./Modals.css";

export default function GeneralPopup(props) {
    // Props (Required) 
		// titleText : String|jsx
		// bodyText : String|jsx
		// showModal: Boolean
		// setShowModal: Function
		// confirm: Function
	// Props (Optional):
		// NoText: String|jsx
		// yesText: String|jsx
		// confirmNo: Function
	
	return (
		<Modal
			onHide={() => props.setShowModal(true)}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			show={props.showModal}
			style={{ opacity: '1' }}
		>
			<Modal.Header>
			<img onClick={() => props.setShowModal(false)} src={Cancel} style={{width: "26px", position: 'absolute', right: '1.5rem', top: '1.5rem' , backgroundColor: 'transparent' , cursor: 'pointer', border: 'none'}}/>
				<Modal.Title id="contained-modal-title-vcenter">
					<p className="modal-title-x mt-5 mb-0">{props.titleText}</p>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p className="text-center p-2">{props.bodyText}</p>
			</Modal.Body>
			<Modal.Footer>
				<Button className="modal-btn-x" onClick={()=>{
                    props.setShowModal(false);
					props.confirm();
                }}> { props.yesText ?? 'Yes' } </Button>
				<Button className="modal-btn-x" onClick={() =>{
					props.setShowModal(false);
					if(props.confirmNo){
						props.confirmNo();
					}
				}}>
					{ props.noText ?? 'No' }
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
